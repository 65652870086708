<template>
  <CytomineModalCard
    :title="$t('annotation-comments')"
    @close="$parent.close()"
  >
    <div v-if="!comments || !comments.length">
      <em class="has-text-grey">
        {{ $t('no-annotation-comments') }}
      </em>
      <hr />
    </div>
    <template v-else>
      <div class="comments-wrapper">
        <div v-for="comment in comments" :key="comment.id">
          <p class="comment-sender is-size-7">
            <strong>{{ comment.senderName }}</strong>
            <span class="has-text-grey">{{
              Number(comment.created) | date('ll LT')
            }}</span>
          </p>
          <p class="comment-content">
            {{ comment.comment }}
          </p>
          <hr />
        </div>
      </div>
    </template>

    <div v-show="!addingComment" class="has-text-centered">
      <button class="button is-link" @click="addingComment = true">
        {{ $t('button-add-comment') }}
      </button>
    </div>

    <div v-show="addingComment">
      <h2 class="mb-3">
        {{ $t('add-new-comment') }}
      </h2>
      <BMessage type="is-info" has-icon size="is-small">
        {{ $t('comment-will-be-sent-by-email') }}
      </BMessage>
      <BField>
        <BRadio
          v-model="sendToAllMembers"
          :native-value="true"
          :disabled="members.length == 0"
        >
          {{ $t('send-to-all-project-members') }}
        </BRadio>
      </BField>
      <BField>
        <BRadio v-model="sendToAllMembers" :native-value="false">
          {{ $t('send-to-some-members') }}
        </BRadio>
      </BField>
      <BField
        v-if="!sendToAllMembers"
        :type="{ 'is-danger': errors.has('members') }"
        :message="errors.first('members')"
      >
        <DomainTagInput
          v-model="selectedMembers"
          v-validate="'required'"
          :domains="members"
          placeholder="search-user"
          name="members"
          searched-property="fullName"
          displayed-property="fullName"
        />
      </BField>
      <BField
        :type="{ 'is-danger': errors.has('comment') }"
        :message="errors.first('comment')"
      >
        <BInput
          v-model="text"
          v-validate="'required'"
          :placeholder="$t('enter-comment')"
          type="textarea"
          rows="2"
          name="comment"
        />
      </BField>
      <p class="buttons is-right are-small">
        <button
          :disabled="loading"
          class="button"
          @click="addingComment = false"
        >
          {{ $t('cancel') }}
        </button>
        <button
          :class="{ 'is-loading': loading }"
          :disabled="loading || members.length == 0 || errors.any()"
          class="button is-link"
          @click="share()"
        >
          {{ $t('button-share') }}
        </button>
      </p>
    </div>
  </CytomineModalCard>
</template>

<script>
import { AnnotationComment } from 'cytomine-client';
import DomainTagInput from '@/components/utils/DomainTagInput.vue';
import CytomineModalCard from '@/components/utils/CytomineModalCard.vue';
import { fullName } from '@/utils/user-utils.js';

export default {
  name: 'AnnotationCommentsModal',
  components: {
    DomainTagInput,
    CytomineModalCard,
  },
  $_veeValidate: { validator: 'new' },
  props: {
    annotation: Object,
    comments: Array,
  },
  data() {
    return {
      addingComment: false,
      sendToAllMembers: true,
      selectedMembers: [],
      text: '',
      loading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    allMembers() {
      return this.$store.state.currentProject.members;
    },
    members() {
      // all project members except current user
      return this.allMembers.filter(
        (member) => member.id !== this.currentUser.id
      );
    },
    annotationURL() {
      const uri = `project/${this.annotation.project}/image/${this.annotation.image}/annotation/${this.annotation.id}`;
      return `${window.location.origin}/#/${uri}`;
    },
  },
  watch: {
    addingComment() {
      this.text = '';
      this.selectedMembers = [];
      this.$nextTick(() => {
        this.sendToAllMembers = true;
        this.$validator.reset();
      });
    },
  },
  methods: {
    async share() {
      const result = await this.$validator.validateAll();
      if (!result) {
        return;
      }

      this.loading = true;
      try {
        const sender = fullName(this.currentUser);
        const newComment = await new AnnotationComment({
          annotation: this.annotation,
          subject: `imageDx: ${sender} commented an annotation`, // not translated because the content of the mail will be in english
          from: sender,
          receivers: (this.sendToAllMembers
            ? this.members
            : this.selectedMembers
          ).map((m) => m.id),
          comment: this.text,
          annotationURL: this.annotationURL,
          shareAnnotationURL: this.annotationURL + '?action=comments',
        }).save();
        this.$emit('addComment', newComment);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-new-comment'),
        });
        this.addingComment = false;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-new-comment'),
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
>>> .modal-card {
  max-height: 80vh;
}

>>> .modal-card-body {
  display: flex;
  flex-direction: column;
}

.comments-wrapper {
  overflow: auto;
  margin-bottom: 1em;
  min-height: 5em;
}

.comment-sender strong {
  margin-right: 0.5em;
}

.comment-content {
  margin-top: 0.25em;
  margin-bottom: 0.5em;
}

>>> textarea {
  margin: 0.75em 0 0;
}

hr {
  margin: 0.75em 0 !important;
}

>>> .dropdown-content {
  max-height: 7em !important;
}
</style>
